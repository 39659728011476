type ContinueWithGoogleButtonProps = {
  onClick: VoidFunction;
}

export const ContinueWithGoogleButton = ({onClick}:ContinueWithGoogleButtonProps):JSX.Element => {
  return(
    <button className="gsi-material-button" onClick={onClick}>
      <div className="gsi-material-button-state"></div>
      <div className="gsi-material-button-content-wrapper">
        <div className="gsi-material-button-icon">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: "block"}}>
            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
        <span className="gsi-material-button-contents">Continue with Google</span>
        <span style={{display: "none"}}>Continue with Google</span>
      </div>
    </button>
  )
}

type GoogleLogoProps = {
  className: string;
}

export const GoogleLogo = ({className}:GoogleLogoProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M22.56 12.2498C22.56 11.4698 22.49 10.7198 22.36 9.99976H12V14.2548H17.92C17.665 15.6298 16.89 16.7948 15.725 17.5748V20.3348H19.28C21.36 18.4198 22.56 15.5998 22.56 12.2498Z"
        fill="#4285F4"
      />
      <path
        d="M11.9999 22.9998C14.9699 22.9998 17.4599 22.0148 19.2799 20.3348L15.7249 17.5748C14.7399 18.2348 13.4799 18.6248 11.9999 18.6248C9.13492 18.6248 6.70992 16.6898 5.84492 14.0898H2.16992V16.9398C3.97992 20.5348 7.69992 22.9998 11.9999 22.9998Z"
        fill="#34A853"
      />
      <path
        d="M5.845 14.0898C5.625 13.4298 5.5 12.7248 5.5 11.9998C5.5 11.2748 5.625 10.5698 5.845 9.90981V7.05981H2.17C1.4 8.59267 0.999321 10.2844 1 11.9998C1 13.7748 1.425 15.4548 2.17 16.9398L5.845 14.0898Z"
        fill="#FBBC05"
      />
      <path
        d="M11.9999 5.37476C13.6149 5.37476 15.0649 5.92976 16.2049 7.01976L19.3599 3.86476C17.4549 2.08976 14.9649 0.999756 11.9999 0.999756C7.69992 0.999756 3.97992 3.46476 2.16992 7.05976L5.84492 9.90976C6.70992 7.30976 9.13492 5.37476 11.9999 5.37476Z"
        fill="#EA4335"
      />
    </svg>
  );
};
