import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@/context';
import { LoadingView } from './Loaders';

const PrivateRoute = () => {
  const { loaded: authLoaded, authenticated } = useAuth();

  if (!authLoaded) {
    return <LoadingView/>;
  }
  
  if (!authenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;