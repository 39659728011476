import { Outlet, Link, useParams, useNavigate } from 'react-router-dom';
import { Sheet, SheetTrigger, SheetContent } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { CircleUserIcon, MenuIcon, SearchIcon } from "./components";
import { TraderGraphIcon } from "./components/TraderGraphIcon";
import { signOut } from 'firebase/auth';
import { appFirebaseAuth, useUserAccounts } from '@/context';
import { useEffect } from 'react';

export const DashboardLayout = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { selectAccount } = useUserAccounts();
  
  useEffect(() => {
    if (!accountId) return;
    selectAccount(accountId);
  }, [accountId, selectAccount]);

  
  const handleLogoutClick = async () => {
    signOut(appFirebaseAuth);
    navigate('/login');
  }
  
  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link to={`/account/${accountId}`} className="flex items-center gap-2 text-lg font-semibold min-w-[24px] min-h-[24px] bg-transparent">
            <TraderGraphIcon className="h-6 w-20" />
            <span className="sr-only">TraderDashboard</span>
          </Link>
          <Link to={`/account/${accountId}`} className="text-foreground transition-colors hover:text-foreground">
            Dashboard
          </Link>
          <Link to={`/account/${accountId}/pairs`} className="text-muted-foreground transition-colors hover:text-foreground">
            Pairs
          </Link>
          <Link to="#" className="text-muted-foreground transition-colors hover:text-foreground">
            Trades
          </Link>
          <Link to={`/account/${accountId}/funds`} className="text-muted-foreground transition-colors hover:text-foreground">
            Funds
          </Link>
          <Link to="#" className="text-muted-foreground transition-colors hover:text-foreground">
            Trends
          </Link>
          <Link to="/accounts" className="text-muted-foreground hover:text-foreground" >
            Accounts
          </Link>
          </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" className="shrink-0 md:hidden">
              <MenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link to="#" className="flex items-center gap-2 text-lg font-semibold min-w-[24px] min-h-[24px]" >
                <TraderGraphIcon className="h-6 w-6" />
                <span className="sr-only">TraderDashboard</span>
              </Link>
              <Link to={`/account/${accountId}`}className="hover:text-foreground" >
                Dashboard
              </Link>
              <Link to={`/account/${accountId}/pairs`} className="text-muted-foreground hover:text-foreground" >
                Pairs
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Trades
              </Link>
              <Link to={`/account/${accountId}/funds`} className="text-muted-foreground hover:text-foreground" >
                Funds
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Trends
              </Link>
              <Link to="/accounts" className="text-muted-foreground hover:text-foreground" >
                Accounts
              </Link>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial">
            <div className="relative">
              <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search knowledge base..."
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
              />
            </div>
          </form>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUserIcon className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleLogoutClick}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <Outlet />
      </main>
    </div>
  );
};