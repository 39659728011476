import { UserAccountsResponse } from "@/types/FirebaseFunctionsResponses";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export const UserAccountsApi = {
  getAccounts: async (): Promise<UserAccountsResponse | undefined> => {
    try{
      const getUserAccount = httpsCallable(functions, 'getUserAccount');
      const result = await getUserAccount();
      const response: UserAccountsResponse = result.data as UserAccountsResponse;

      console.log("firebaseFunctionsApi.getAccounts response:", response);
      return response;
    }
    catch(e){
      console.error("UserAccountsApi.getAccounts error:", e);
      return undefined;
    }
  }
}

