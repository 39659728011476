import icons from 'currency-icons';
import { ArrowRightLeft } from "lucide-react"

export const PairIcon = ({ pairName }: { pairName: string }) => {
  const [currency1, currency2] = [pairName.slice(0, 3), pairName.slice(3, 6)];
  const icon1 = icons[currency1]?.icon;
  const icon2 = icons[currency2]?.icon;

  return (
    <div className="flex items-center">
      {icon1 && <img src={icon1} alt={currency1} className="w-6 h-6 mr-1" />}
      <ArrowRightLeft className="mx-1" size={'1rem'} color='grey'/>
      {icon2 && <img src={icon2} alt={currency2} className="w-6 h-6 ml-1" />}
    </div>
  );
};