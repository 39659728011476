import { useState } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { appFirebaseAuth } from '@/context'; // Import your app state context
import { Plus, XIcon, Edit2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Sheet, SheetTrigger, SheetContent } from "@/components/ui/sheet";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { CircleUserIcon, MenuIcon, SearchIcon } from "./Dashboard/components";
import { TraderGraphIcon } from "./Dashboard/components/TraderGraphIcon";
import { signOut } from 'firebase/auth';
import { useUserAccounts } from '@/context/UserAccountsContext';

export const AccountSelection = (): JSX.Element => {
  const [showNewAccountInput, setShowNewAccountInput] = useState(false);
  const [newAccountId, setNewAccountId] = useState('');
  const { accounts, updateAccountNickname } = useUserAccounts();
  const [editingNickname, setEditingNickname] = useState<string | null>(null);
  const [newNickname, setNewNickname] = useState('');

  const navigate = useNavigate();

  const handleAddAccount = async () => {
    if (newAccountId.trim()) {
      try {
        // const addAccount = httpsCallable(functions, 'addAccount');
        // const result = await addAccount({ id: newAccountId });
        // navigate(`/add-account/${newAccountId}`);
        console.log('Adding account:', newAccountId);
        // For now, just log the new account ID
      } catch (error) {
        console.error('Error adding account:', error);
        // Handle error (show message to user, etc.)
      }
    }
  };

  const handleEditNickname = (accountId: string) => {
    const account = accounts!.find(acc => acc.id === accountId)!;
    setEditingNickname(accountId);
    setNewNickname(account.nickname || '');
  };

  const handleSaveNickname = async (accountId: string) => {
    const account = accounts!.find(acc => acc.id === accountId)!;
    if (newNickname.trim() !== account.nickname) {
      try {
        await updateAccountNickname(accountId, newNickname);
      } catch (error) {
        console.error('Error updating nickname:', error);
      }
    }
    setEditingNickname(null);
  };

  const handleLogoutClick = async () => {
    signOut(appFirebaseAuth);
    navigate('/login');
  }
  
  return (
    <div className="flex min-h-screen w-full flex-col">
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <Link to={`/accounts`} className="flex items-center gap-2 text-lg font-semibold min-w-[24px] min-h-[24px] bg-transparent">
            <TraderGraphIcon className="h-6 w-20" />
            <span className="sr-only">TraderDashboard</span>
          </Link>
          <Link to={`/accounts`} className="text-foreground transition-colors hover:text-foreground">
            Accounts
          </Link>
          </nav>
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline" size="icon" className="shrink-0 md:hidden">
              <MenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <nav className="grid gap-6 text-lg font-medium">
              <Link to="#" className="flex items-center gap-2 text-lg font-semibold min-w-[24px] min-h-[24px]" >
                <TraderGraphIcon className="h-6 w-6" />
                <span className="sr-only">TraderDashboard</span>
              </Link>
              <Link to="#" className="hover:text-foreground" >
                Dashboard
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Pairs
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Trades
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Transfers
              </Link>
              <Link to="#" className="text-muted-foreground hover:text-foreground" >
                Trends
              </Link>
              <Link to="/accounts" className="text-muted-foreground hover:text-foreground" >
                Accounts
              </Link>
            </nav>
          </SheetContent>
        </Sheet>
        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial">
            <div className="relative">
              <SearchIcon className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search knowledge base..."
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"
              />
            </div>
          </form>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUserIcon className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Settings</DropdownMenuItem>
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleLogoutClick}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <h1 className="text-2xl font-bold mb-6">My Accounts</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {accounts && accounts.map(account => (
            <Card 
              key={account.id} 
              className="hover:shadow-lg transition-shadow cursor-pointer group"
              onClick={() => navigate(`/account/${account.id}`)}
            >
              <CardContent className="flex flex-col items-center justify-center h-40 relative">
                <span className="text-2xl font-bold">{account.id}</span>
                {editingNickname === account.id ? (
                  <Input
                    value={newNickname}
                    onChange={(e) => setNewNickname(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSaveNickname(account.id);
                      }
                    }}
                    className="w-full text-sm mt-2"
                    onClick={(e) => e.stopPropagation()}
                  />
                ) : (
                  <span className="text-sm text-gray-500 mt-1">{account.nickname || 'No nickname'}</span>
                )}
                <Button
                  variant="ghost"
                  size="sm"
                  className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditNickname(account.id);
                  }}
                >
                  <Edit2 className="w-4 h-4" />
                </Button>
              </CardContent>
            </Card>
          ))}
          
          <Card className="hover:shadow-lg transition-shadow">
            <CardContent className="flex items-center justify-center h-40">
              {!showNewAccountInput ? (
                <Button 
                  onClick={() => setShowNewAccountInput(true)}
                  variant="outline"
                  className="w-16 h-16 rounded-full"
                >
                  <Plus className="w-8 h-8" />
                </Button>
              ) : (
                <div className="w-full space-y-2">
                  <Input
                    value={newAccountId}
                    onChange={(e) => setNewAccountId(e.target.value)}
                    placeholder="Enter account ID"
                    className="w-full"
                  />
                  <div className="flex justify-between">
                    <Button onClick={handleAddAccount} className="flex-1 mr-2">
                      Add Account
                    </Button>
                    <Button 
                      onClick={() => {
                        setShowNewAccountInput(false);
                        setNewAccountId('');
                      }} 
                      variant="outline"
                      className="flex-none"
                    >
                      <XIcon className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
};