import { Card, CardHeader, CardTitle, CardFooter, CardContent, CardDescription } from "@/components/ui/card"
import { Progress } from "@/components/ui/progress";
import { useFunds } from "@/context/FundsContext";
import { Button } from "@chakra-ui/react";
import { formatDate } from "date-fns";
import { useEffect, useState } from "react";

const formatNumber = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};

type BaseMetricsProps = {
  title: string;
  value: string;
  progress?: number;
  progressTitle?: string;
};

type MetricsProps = {
  title: string;
  value: number;
  progress?: number;
  progressTitle?: string;
};

export const BaseMetrics = ({value, title, progress, progressTitle}:BaseMetricsProps):JSX.Element => {
  const getProgress = (progress: number): JSX.Element => {
    return(
      <div>
        <CardContent>
          <div className="text-xs text-muted-foreground">
            {progressTitle}
          </div>
        </CardContent>
        <CardFooter>
          <Progress value={progress} aria-label="25% increase" />
        </CardFooter>
      </div>
    )
  }

  return (
    <Card x-chunk="dashboard-05-chunk-1">
      <CardHeader className="pb-2">
        <CardDescription>{title}</CardDescription>
        <CardTitle className="text-4xl">{value}</CardTitle>
      </CardHeader>
      {progress && getProgress(progress)}
    </Card>
  )
}

export const NumberMetrics = ({title, value, progress, progressTitle}:MetricsProps):JSX.Element => {
  const valueString = value.toLocaleString();

  return (
    <BaseMetrics title={title} value={valueString} progress={progress} progressTitle={progressTitle}/>
  )
}


export const UsdMetrics = ({title, value, progress, progressTitle}:MetricsProps):JSX.Element => {
  const valueString = "$" + formatNumber(value);

  return (
    <BaseMetrics title={title} value={valueString} progress={progress} progressTitle={progressTitle}/>
  )
}

export const PercentageMetrics = ({title, value, progress, progressTitle}:MetricsProps):JSX.Element => {
  const valueString = formatNumber(value)+"%";

  return (
    <BaseMetrics title={title} value={valueString} progress={progress} progressTitle={progressTitle}/>
  )
}

export const MarginLevelMetrics = ({value, title, progress, progressTitle}:MetricsProps):JSX.Element => {
  const valueString = formatNumber(value)+"%";

  const getMarginLevelProgress = (level: number): JSX.Element => {
    const logValue = Math.log10(level);
    const maxLogValue = 4; // Corresponds to 10,000%
    const progressValue = (Math.min(logValue, maxLogValue) / maxLogValue) * 100;

    let color = 'bg-green-500';
    let progressLable = 'Safe';

    if (level <= 300) {
      color = 'bg-red-500';
      progressLable = 'Risky';
    } else if (level <= 1000) {
      color = 'bg-orange-500';
      progressLable = 'Pay attention';
    }

    return (
      <div>
        <CardContent>
          <div className="text-xs text-muted-foreground">
            {progressLable}
          </div>
        </CardContent>
        <CardFooter>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className={`${color} h-2.5 rounded-full`} style={{width: `${progressValue}%`}}></div>
          </div>
        </CardFooter>
      </div>
    )
  }

  return (
    <Card x-chunk="dashboard-05-chunk-1">
      <CardHeader className="pb-2">
        <CardDescription>{title}</CardDescription>
        <CardTitle className="text-4xl">{valueString}</CardTitle>
      </CardHeader>
      {value && getMarginLevelProgress(value)}
    </Card>
  )
}

type BalanceMetricsProps = {
  value: number;
  title: string;
  lastUpdatedAt: Date;
  onRefreshRequested?: () => void;
}

export const BalanceMetrics = ({value, title, lastUpdatedAt, onRefreshRequested}:BalanceMetricsProps):JSX.Element => {
  const valueString = '$' + formatNumber(value);
  const formattedDate = formatDate(lastUpdatedAt, "HH:mm MMM dd, yyyy");
  const [isOld, setIsOld] = useState(false);

  const {loaded: fundsLoaded, transactions, fundsStats} = useFunds();
  
  useEffect(() => {
    const checkIfOld = () => {
      const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
      setIsOld(new Date(lastUpdatedAt) < fiveMinutesAgo);
    };

    checkIfOld();
    const interval = setInterval(checkIfOld, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [lastUpdatedAt]);

  let equitySubtitle;

  if (!fundsLoaded) {
    equitySubtitle='Loading funds details';
  }
  else if (!transactions || !fundsStats) {
    equitySubtitle='Update your transactions to learn equity profit';
  }
  else {
    const allTimeProfit = fundsStats.profit;
    const allTimeProfitPercentage = allTimeProfit / fundsStats.totalDeposits * 100;

    equitySubtitle = allTimeProfitPercentage.toFixed(2) + '% over time from all time equity';
  }

  return (
    <Card x-chunk="dashboard-05-chunk-1">
      <CardHeader className="pb-2">
        <CardDescription>{title}</CardDescription>
        <CardTitle className="text-4xl">{valueString}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-xs text-muted-foreground">
          {equitySubtitle}
        </p>
      </CardContent>
      <CardFooter className="flex justify-between items-center">
        <p className={`text-xs ${isOld ? 'text-red-500' : 'text-muted-foreground'}`}>
          Last updated: {formattedDate} {isOld && <span className="text-red-500">!</span>}
        </p>
        <Button 
          onClick={onRefreshRequested} 
          variant="ghost" 
          size="icon"
          className="text-muted-foreground hover:text-foreground"
          disabled={!onRefreshRequested}
          aria-label="Refresh"
        >
          <RefreshIcon className="h-4 w-4" />
        </Button>
      </CardFooter>
    </Card>
  )
}

const RefreshIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3" />
  </svg>
);