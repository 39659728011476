import React, { useMemo, useState } from 'react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Link } from 'react-router-dom';
import { PairIcon } from './PairIcon';
import { ArrowUpRightIcon } from 'lucide-react';
import { PairConfiguration, PairInfo } from '@/types';

export type DisplayPair = PairInfo & {
  isInConfig?: boolean;
  isActive?: boolean;
  recommendedReduceLotSize?: boolean;
  recommendedLotSize?: number;
};

type PairsTableProps = {
  pairs: PairInfo[];
  pairConfigurations: PairConfiguration[];
  totalProfit: number;
  showAllPairs?: boolean;
  accountId?: string;
  maxPairs?: number;
  showTypeColumn?: boolean;
};

export const PairsTable = ({
  pairs,
  pairConfigurations,
  totalProfit,
  showAllPairs = false,
  accountId,
  maxPairs = 5,
  showTypeColumn = true,
}: PairsTableProps): JSX.Element => {
  const [selectedConfiguration, setSelectedConfiguration] = useState<string>("Active");

  const configurations: PairConfiguration[] = useMemo(() => [
    { name: "Active", pairs: [] },
    { name: "All", pairs: [] },
    ...pairConfigurations
  ], [pairConfigurations]);

  const displayPairs = useMemo(() => {
    const selectedConfig = configurations.find(c => c.name === selectedConfiguration);
    if (!selectedConfig) return pairs as DisplayPair[];

    let configPairs: DisplayPair[];
    if (selectedConfiguration === "Active") {
      configPairs = pairs.map(p => ({ ...p, isActive: true, isInConfig: true }));
    } else if (selectedConfiguration === "All") {
      configPairs = Array.from(new Set([
        ...pairConfigurations.flatMap(config => config.pairs.map(p => p.name)),
        ...pairs.map(p => p.symbol)
      ])).map(symbol => {
        const activePair = pairs.find(p => p.symbol === symbol);
        const configPair = pairConfigurations.flatMap(config => config.pairs).find(p => p.name === symbol);
        return {
          symbol,
          profit: activePair?.profit || 0,
          minLot: activePair?.minLot || 0,
          maxLot: activePair?.maxLot || 0,
          openOrders: activePair?.openOrders || 0,
          recommendedReduceLotSize: configPair?.recommendedReduceLotSize,
          recommendedLotSize: configPair?.recommendedLotSize,
          isInConfig: !!configPair,
          isActive: !!activePair
        };
      });
    } else {
      configPairs = selectedConfig.pairs.map(p => {
        const activePair = pairs.find(ap => ap.symbol === p.name);
        return {
          symbol: p.name,
          profit: activePair?.profit || 0,
          minLot: activePair?.minLot || 0,
          maxLot: activePair?.maxLot || 0,
          openOrders: activePair?.openOrders || 0,
          recommendedReduceLotSize: p.recommendedReduceLotSize,
          recommendedLotSize: p.recommendedLotSize,
          isInConfig: true,
          isActive: !!activePair
        };
      });
      
      pairs.forEach(activePair => {
        if (!configPairs.some(p => p.symbol === activePair.symbol)) {
          configPairs.push({...activePair, isInConfig: false, isActive: true});
        }
      });
    }

    configPairs.sort((a, b) => a.profit - b.profit);

    return showAllPairs ? configPairs : configPairs.slice(0, maxPairs);
  }, [pairs, pairConfigurations, selectedConfiguration, showAllPairs, maxPairs, configurations]);

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center">
        <div className="grid gap-2">
          <CardTitle className="text-2xl">{showAllPairs ? 'All Pairs' : 'Active Pairs'}</CardTitle>
          <CardDescription>
            {showAllPairs ? 'All currency pairs for the account.' : 'Most active currency pairs for the account.'}
          </CardDescription>
        </div>
        {showAllPairs && (
          <Select
            value={selectedConfiguration}
            onValueChange={setSelectedConfiguration}
          >
            <SelectTrigger className="w-[250px] ml-auto">
              <SelectValue placeholder="Select configuration" />
            </SelectTrigger>
            <SelectContent>
              {configurations.map(config => (
                <SelectItem key={config.name} value={config.name}>
                  {config.name === "Active" ? "Active Pairs" : 
                   config.name === "All" ? "All Pairs" : 
                   config.name }
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
        {!showAllPairs && accountId && (
          <Button asChild size="sm" className="ml-auto gap-1">
            <Link to={`/account/${accountId}/pairs`}>
              View All
              <ArrowUpRightIcon className="h-4 w-4" />
            </Link>
          </Button>
        )}
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-lg">Pair</TableHead>
              <TableHead></TableHead> {/* Empty header for icon column */}
              {showTypeColumn && <TableHead className="text-lg">Type</TableHead>}
              <TableHead className="text-center text-lg">Lot Size</TableHead>
              <TableHead className="text-center text-lg">Max Lot</TableHead>
              <TableHead className="text-lg text-center">Open Trades</TableHead>
              <TableHead className="text-right text-lg">Profit/Loss</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {displayPairs.map((pair) => (
              <TableRow key={pair.symbol} 
              className={`
                hover:bg-transparent
                ${selectedConfiguration !== "Active" && selectedConfiguration !== "All" 
                  ? pair.isInConfig === false 
                    ? "bg-blue-50 hover:bg-blue-50" 
                    : !pair.isActive 
                      ? "bg-gray-100 hover:bg-gray-100"
                      : ""
                  : ""}
              `}>
                <TableCell>
                  <div className="font-medium text-lg">{pair.symbol}</div>
                </TableCell>
                <TableCell>
                  <PairIcon pairName={pair.symbol} />
                </TableCell>
                {showTypeColumn && (
                  <TableCell>
                    <div className="font-medium">
                      {selectedConfiguration !== "Active" && selectedConfiguration !== "All" ? selectedConfiguration : ""}
                      {pair.recommendedReduceLotSize && ' (recommended 50% lot size)'}
                      {pair.recommendedLotSize && ` (recommended ${pair.recommendedLotSize} lot size)`}
                    </div>
                  </TableCell>
                )}
                <TableCell className="text-right">
                  <div className="font-medium text-lg text-center">{pair.minLot.toFixed(2)}</div>
                </TableCell>
                <TableCell className="text-right">
                  <div className="font-medium text-lg text-center">{pair.maxLot.toFixed(2)}</div>
                </TableCell>
                <TableCell className="text-right">
                  <div className="font-medium text-lg text-center">{pair.openOrders}</div>
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex justify-end items-center space-x-2">
                    <span className="text-sm text-muted-foreground">
                      ({((pair.profit / totalProfit) * 100).toFixed(2)}%)
                    </span>
                    <div className={`font-medium text-lg ${pair.profit >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                      {pair.profit.toFixed(2)}$
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {!showAllPairs && displayPairs.length < maxPairs && (
              <TableRow>
                <TableCell colSpan={showTypeColumn ? 6 : 5} className="text-center text-muted-foreground text-lg">
                  No more active pairs
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};