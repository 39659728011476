
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {LoginPage, AccountDashboard, AccountSelection, SignupPage, 
  AppLoader, DashboardLayout, PairsPage, FundsPage} from './pages';

import './index.css';
import './ux/globals.css';
import { RootProvider } from './context/RootProvider';
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {
  return (
    <RootProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          
          <Route element={<PrivateRoute />}>
            <Route path="/app" element={<AppLoader />} />
            <Route path="/dashboard" element={<AccountDashboard />} />
            <Route path="/account/:accountId" element={<DashboardLayout />}>
              <Route index element={<AccountDashboard />} />
              <Route path="pairs" element={<PairsPage />} />
              <Route path="funds" element={<FundsPage />} />
            </Route>
            <Route path="/accounts" element={<AccountSelection />} />
          </Route>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </BrowserRouter>
    </RootProvider>
  );
};

export default App;