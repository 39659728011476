import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { FundsApi } from '@/api/FundsApi';
import { Transaction } from '@/types';
import { useUserAccounts } from './UserAccountsContext';
import { useAccountData } from './AccountDataContext';

type FundsStats = {
  totalDeposits: number;
  totalWithdrawals: number;
  currentBalance: number;
  profit: number;
  unrealizedProfit: number;
};

interface FundsContextType {
  loaded: boolean;

  transactions: Transaction[] | undefined;
  fundsStats: FundsStats | undefined;

  add: (accountId: string, type: 'deposit' | 'withdrawal', amount: number, date: Date) => Promise<void>;
  refresh: (accountId: string) => Promise<void>;
}

const FundsContext = createContext<FundsContextType | undefined>(undefined);

interface FundsProviderProps {
  children: React.ReactNode;
}

export const FundsProvider: React.FC<FundsProviderProps> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[] | undefined>(undefined);
  const [fundsStats, setFundsStats] = useState<FundsStats | undefined>(undefined);

  const {selectedAccountId} = useUserAccounts();
  const {loaded: accountDataLoaded, accountData} = useAccountData();
  
  const calculateFundsStats = useCallback( (transactions: Transaction[]):FundsStats | undefined => {
    let totalDeposits = 0;
    let totalWithdrawals = 0;

    transactions.forEach(transaction => {
      if (transaction.type === 'deposit') {
        totalDeposits += transaction.amount;
      } else if (transaction.type === 'withdrawal') {
        totalWithdrawals += transaction.amount;
      }
    });
    
    const equity = totalDeposits - totalWithdrawals;

    const currentBalance = (accountData && accountData.balance) || 0;

    const profit = currentBalance - equity;
    const unrealizedProfit = currentBalance - (totalDeposits - totalWithdrawals);

    return {
      totalDeposits,
      totalWithdrawals,
      currentBalance,
      profit,
      unrealizedProfit,
    }
  }, [accountData]);

  const load = useCallback(async (accountId: string) => {
    try {
      setLoaded(false);
      
      if (!accountDataLoaded) return;

      const response = await FundsApi.getFundsTransactions(accountId);
      if (!response) {
        setTransactions([]);
        setLoaded(true);
        return;
      }

      let transactions = response?.transactions!;

      setTransactions(response!.transactions);
      setFundsStats(calculateFundsStats(transactions));
      setLoaded(true);
    } catch (error) {
      console.error('Error loading funds data:', error);
      setLoaded(true);
    }
  }, [accountDataLoaded, calculateFundsStats]);

  useEffect(() => {
    if (!selectedAccountId) return;

    load(selectedAccountId);
  }, [selectedAccountId, load]);

  const add = useCallback(async (accountId: string, type: 'deposit' | 'withdrawal', amount: number, date: Date) => {
    try {
      await FundsApi.addTransaction(accountId, type, amount, date);
      await load(accountId);
    } catch (error) {
      console.error('Error adding transaction:', error);
      throw error;
    }
  }, [load]);

  const refresh = useCallback(async (accountId: string) => {
    await load(accountId);
  }, [load]);

  const contextValue = {
    loaded,

    transactions,
    fundsStats,

    add,
    refresh,
  };

  return <FundsContext.Provider value={contextValue}>{children}</FundsContext.Provider>;
};


export const useFunds = () => {
  const context = useContext(FundsContext);
  
  if (context === undefined) {
    throw new Error('useFunds must be used within a FundsProvider');
  }

  return context;
};