import { useState } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Link } from "@chakra-ui/react"
import { GoogleLogo } from "@/components"
import { useNavigate } from "react-router-dom"
import { signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { appFirebaseAuth, googleProvider, useAuth } from '@/context';
import { useEffect } from "react"
import {AppLoader as AppLoaderAnimation} from '@/components/Loaders';

interface AuthCardProps {
  title: string;
  description: string;
  buttonText: string;
  isSignup: boolean;
  toggleText: string;
  toggleLink: string;
}

export const AuthCard = ({ 
  title, 
  description, 
  buttonText, 
  isSignup,
  toggleText,
  toggleLink
}: AuthCardProps): JSX.Element => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { loaded: authLoaded, setUser, authenticated } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!authLoaded) return;

    if (authenticated) {
      navigate('/app');
    }

    setLoaded(true);
  }, [navigate, authLoaded, authenticated]);

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      let result;
      if (isSignup) {
        result = await createUserWithEmailAndPassword(appFirebaseAuth, email, password);
      } else {
        result = await signInWithEmailAndPassword(appFirebaseAuth, email, password);
      }
      console.log(`User ${isSignup ? 'signed up' : 'logged in'}:`, result);
      setUser(result.user);
    } catch (error) {
      console.error('Error during email auth:', error);
      setError(`Error: ${(error as Error).message}`);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(appFirebaseAuth, googleProvider);
      console.log('User logged in with Google:', result);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
      setError(`Error: ${(error as Error).message}`);
    }
  };

  if (!loaded) {
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <AppLoaderAnimation />
      </div>
    )
  }

  return (
    <Card className="w-full max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <form onSubmit={handleEmailAuth}>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input 
              id="email" 
              type="email" 
              placeholder="m@example.com" 
              required 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>
            <Input 
              id="password" 
              type="password" 
              required 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {!isSignup && (
            <Link href="/forgot-password" className="text-sm text-blue-600 hover:underline">
              Forgot password?
            </Link>
          )}
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </CardContent>
        <CardFooter style={{flexDirection: 'column'}} className="gap-4">
          <Button type="submit" className="w-full">{buttonText}</Button>
          <div className="text-sm text-center">
            {toggleText} <Link href={toggleLink} className="text-blue-600 hover:underline">{isSignup ? 'Sign in' : 'Sign up'}</Link>
          </div>
          <div className="flex items-center gap-2 w-full">
            <hr className="flex-grow border-gray-300" />
            <span className="text-gray-500">or</span>
            <hr className="flex-grow border-gray-300" />
          </div>
          <Button type="button" size="sm" className="w-full gap-1" onClick={handleGoogleLogin}>
            Continue with Google
            <GoogleLogo className="h-4 w-4" />
          </Button>
        </CardFooter>
      </form>
    </Card>
  )
}