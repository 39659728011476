import { Image } from "@chakra-ui/react"
import traderGraphIcon2 from '@/ux/images/1.png';
import traderGraphSvg from '@/ux/images/trader-graph.svg';

type IconProps = {
  className?: string;
}

export const TraderGraphIcon = ({className}:IconProps):JSX.Element =>  {
  return (
    <Image
      className={className}
      src={traderGraphIcon2}
      alt="TD"
      display="inline-block"
      //style={{ backgroundColor: 'transparent' }}
      //width={48}
      //height={24} 
    />
  )
}

export const TraderGraphSvg = (props: React.SVGAttributes<SVGElement>):JSX.Element =>  {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {traderGraphSvg}
    </svg>
  )
}
