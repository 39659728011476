import { useParams } from 'react-router-dom';
import { FundsTable, FundsSummary } from './components';
import { useFunds } from '@/context/FundsContext';

export const FundsPage = (): JSX.Element => {
  const { accountId } = useParams();
  const { loaded: fundsLoaded, transactions, fundsStats } = useFunds();

  if (!accountId || !fundsLoaded || !fundsStats) return <div>Loading...</div>;

  return (
    <div className="flex flex-col gap-8">
      <FundsSummary 
        balance={fundsStats.currentBalance}
        equity={fundsStats.totalDeposits - fundsStats.totalWithdrawals}
        profit={fundsStats.profit}
        unrealizedProfit={fundsStats.unrealizedProfit}
      />
      <FundsTable
        transactions={transactions}
      />
    </div>
  );
};