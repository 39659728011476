import * as firebase from 'firebase/app';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence, GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyATtDeU1Va5OPTmult3J091WE7isw49L_8",
  authDomain: "traderdashboard-72a57.firebaseapp.com",
  projectId: "traderdashboard-72a57",
  storageBucket: "traderdashboard-72a57.appspot.com",
  messagingSenderId: "174423554653",
  appId: "1:174423554653:web:c412d54ccd1ed7dd642bb4",
  measurementId: "G-853X6XJ1NT"
};

export let firebaseApp: FirebaseApp | undefined;

if (!firebase.getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
}

export const appFirebaseAuth = getAuth(firebaseApp);
setPersistence(appFirebaseAuth, browserLocalPersistence);

export const googleProvider = new GoogleAuthProvider();