import { FundsTransactionsRequestResponse } from "@/types/FirebaseFunctionsResponses";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export const FundsApi = {
  getFundsTransactions: async (accountId: string): Promise<FundsTransactionsRequestResponse | undefined> => {
    try{
      const getFundsData = httpsCallable(functions, 'getFundsTransactions');
      const result = await getFundsData({accountId});

      console.log("FundsApi.getFundsTransactions response:", result);

      const response: FundsTransactionsRequestResponse = result.data as FundsTransactionsRequestResponse;

      console.log("FundsApi.getFundsTransactions response:", response);

      return response;
    }
    catch(e){
      console.error("FundsApi.getFundsTransactions error:", e);
      return undefined;
    }
  },

  addTransaction: async (accountId: string, type: 'deposit' | 'withdrawal', amount: number, date: Date) => {
    try{
      const addFundsTransaction = httpsCallable(functions, 'addFundsTransaction');
      const result = await addFundsTransaction({accountId, type, amount, date});

      console.log("FundsApi.addTransaction response:", result);
      return result;
    }
    catch(e){
      console.error("FundsApi.addTransaction error:", e);
      return undefined;
    }
  }
}