import React, { useState, useMemo } from 'react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Transaction } from '@/types';
import { useFunds } from '@/context/FundsContext';
import { useUserAccounts } from '@/context';

type FundsTableProps = {
  transactions: Transaction[] | undefined;
};

export const FundsTable = ({
  transactions,
}: FundsTableProps): JSX.Element => {
  const { add: addTransaction } = useFunds();
  const { selectedAccountId } = useUserAccounts();
  const [isAdding, setIsAdding] = useState(false);
  const [newTransaction, setNewTransaction] = useState<Omit<Transaction, 'id'>>({
    type: 'deposit',
    amount: 0,
    date: new Date().toISOString()
  });

  const sortedTransactions = useMemo(() => {
    return transactions ? [...transactions].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : [];
  }, [transactions]);

  const onAddTransaction = () => {
    setIsAdding(true);
  };

  const onSaveTransaction = async () => {
    if (!selectedAccountId) return;

    try {
      await addTransaction(selectedAccountId, newTransaction.type, newTransaction.amount, new Date(newTransaction.date));
      
      setIsAdding(false);
      // Reset new transaction
      setNewTransaction({
        type: 'deposit',
        amount: 0,
        date: new Date().toISOString()
      });
    } catch (error) {
      console.error("Failed to save transaction:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const onCancelAdd = () => {
    setIsAdding(false);
    // Reset new transaction
    setNewTransaction({
      type: 'deposit',
      amount: 0,
      date: new Date().toISOString()
    });
  };

  if (!transactions) return <div>Loading...</div>;

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center">
        <div className="grid gap-2">
          <CardTitle className="text-2xl">Account Funds Transactions</CardTitle>
          <CardDescription>
            Overview of deposits & withdrawals for accurate account performance.
          </CardDescription>
        </div>
        <Button onClick={onAddTransaction} size="sm" className="ml-auto gap-1" disabled={isAdding}>
          Add Transaction
        </Button>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="text-lg">Date</TableHead>
              <TableHead className="text-lg">Type</TableHead>
              <TableHead className="text-right text-lg">Amount</TableHead>
              {isAdding && <TableHead className="text-right text-lg">Actions</TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {isAdding && (
              <TableRow>
                <TableCell>
                  <Input
                    type="date"
                    value={new Date(newTransaction.date).toISOString().split('T')[0]}
                    onChange={(e) => setNewTransaction(prev => ({ ...prev, date: new Date(e.target.value).toISOString() }))}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={newTransaction.type}
                    onValueChange={(value: 'deposit' | 'withdrawal') => setNewTransaction(prev => ({ ...prev, type: value }))}
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="deposit">Deposit</SelectItem>
                      <SelectItem value="withdrawal">Withdrawal</SelectItem>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <Input
                    type="number"
                    value={newTransaction.amount}
                    onChange={(e) => setNewTransaction(prev => ({ ...prev, amount: parseFloat(e.target.value) }))}
                    className="text-right"
                  />
                </TableCell>
                <TableCell className="text-right">
                  <Button onClick={onSaveTransaction} size="sm" className="mr-2">Save</Button>
                  <Button onClick={onCancelAdd} size="sm" variant="outline">Cancel</Button>
                </TableCell>
              </TableRow>
            )}
            {sortedTransactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className="font-medium text-lg">{new Date(transaction.date).toLocaleDateString()}</div>
                </TableCell>
                <TableCell>
                  <div className="font-medium text-lg">{transaction.type}</div>
                </TableCell>
                <TableCell className="text-right">
                  <div className={`font-medium text-lg ${transaction.type === 'deposit' ? 'text-green-600' : 'text-red-600'}`}>
                    ${transaction.amount.toFixed(2)}
                  </div>
                </TableCell>
                {isAdding && <TableCell></TableCell>}
              </TableRow>
            ))}
            {sortedTransactions.length === 0 && !isAdding && (
              <TableRow>
                <TableCell colSpan={3} className="text-center text-muted-foreground text-lg">
                  No transactions yet
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};