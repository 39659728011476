import { Page } from "@/components"
import { AuthCard } from "./AuthCard"

export const SignupPage = (): JSX.Element => {
  return (
    <Page>
      <AuthCard 
        title="Sign Up"
        description="Create an account to get started."
        buttonText="Create account"
        isSignup={true}
        toggleText="Already have an account?"
        toggleLink="/login"
      />
    </Page>
  )
}