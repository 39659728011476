import { useEffect, useState } from "react";
import { MavrixPairsConfigurations } from "@/types";
import { useAccountData, useUserAccounts } from "@/context";
import { PairsTable } from './components';

export const PairsPage = (): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);
  const {selectedAccountId} = useUserAccounts();
  
  const { loaded: accountDataLoaded, accountData, error: accountLoadError } = useAccountData();

  useEffect(() => {
    if (accountLoadError) {
      setError(accountLoadError);
    }
  }, [accountLoadError]);

  if (error) return <div>Error: {error}</div>;
  if (!accountDataLoaded || !accountData) return <div>Loading...</div>;

  return (
    <PairsTable
        pairs={accountData.pairs}
        pairConfigurations={MavrixPairsConfigurations}
        totalProfit={accountData.totalProfit}
        showAllPairs={true}
        accountId={selectedAccountId}
        showTypeColumn={true}
      />
  );
};