import { Page } from "@/components"
import { AuthCard } from "./AuthCard"

export const LoginPage = (): JSX.Element => {
  return (
    <Page>
      <AuthCard 
        title="Login"
        description="Enter your email below to login to your account."
        buttonText="Sign in"
        isSignup={false}
        toggleText="Don't have an account?"
        toggleLink="/signup"
      />
    </Page>
  )
}