import { useEffect, useState } from "react"
import { MavrixPairsConfigurations } from "@/types"
import { useAccountData, useUserAccounts } from "@/context";
import { UsdMetrics, MarginLevelMetrics, BalanceMetrics } from "./components/MainMetrics"
import { PairsTable } from "./components";
import { LoadingView } from '@/components';

export const AccountDashboard = ():JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  
  const {refreshAccountData, loaded: accountDataLoaded, accountData, error:accountLoadError
  } = useAccountData();
  const {selectedAccountId} = useUserAccounts();

  useEffect(() => {
    if (accountLoadError) {
      setError(accountLoadError);
    }
  }, [accountLoadError]);

  useEffect(() => {
    setError(undefined);
    setLoaded(accountDataLoaded);
  }, [accountDataLoaded]);
  
  const getPairsView = (): JSX.Element => {
    if (!accountData) return <div>Loading...</div>;
    
    return (
      <PairsTable
        pairs={accountData.pairs || {}}
        pairConfigurations={MavrixPairsConfigurations}
        totalProfit={accountData.totalProfit}
        showAllPairs={false}
        accountId={selectedAccountId}
        maxPairs={5}
        showTypeColumn={false}
      />
    );
  };

  const onRefreshRequested = async () => {
    await refreshAccountData();
  }

  if (error) return <div>Error: {error}</div>;
  
  if (!loaded || !accountData) return <LoadingView/>;

  const getMarginLevel = (equity: number, freeMargin:number) => {
    const usedMargin = equity - freeMargin;
    const marginLevel = (equity / usedMargin) * 100;
    
    return marginLevel;
  }

  const drawDownPercentage = Math.abs(accountData.totalProfit) / accountData.balance * 100;
  const marginProgress = Math.abs(accountData.freeMargin) / accountData.balance * 100;

  return (
    <div className="flex flex-col gap-8">
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
        <BalanceMetrics title="Balance" value={accountData.balance}
          lastUpdatedAt={accountData.lastUpdatedAt} onRefreshRequested={onRefreshRequested}/>
        <UsdMetrics title="Current Drawdown" 
          value={Math.abs(accountData.totalProfit)} 
          progress={drawDownPercentage}
          progressTitle={drawDownPercentage.toFixed(2) + '% from balance'} 
          />
        <MarginLevelMetrics title="Margin Level" value={getMarginLevel(accountData.equity, accountData.freeMargin)}/>
        <UsdMetrics title="Free Margin" 
          value={accountData.freeMargin} 
          progress={marginProgress}
          progressTitle={marginProgress.toFixed(2) + '% from balance'} 
          />
      </div>
      {getPairsView()}
    </div>
  )
}