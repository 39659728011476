export type PairDescription = {
  name: string;
  recommendedReduceLotSize?: boolean;
  recommendedLotSize?: number;
};

export type PairConfiguration = {
  name: string;
  pairs: PairDescription[];
};

export type PairInfo = {
  symbol: string;
  profit: number;
  minLot: number;
  maxLot: number;
  openOrders?: number;
};

export const MavrixPairsConfigurations: PairConfiguration[] = [
  {
    name: "TH2 - Conservative",
    pairs: [
      { name: "AUDNZD" },
      { name: "EURCAD" },
      { name: "EURUSD" },
      { name: "GBPUSD" },
      { name: "NZDUSD" },
      { name: "USDJPY", recommendedReduceLotSize: true }
    ]
  },
  {
    name: "TH2 - Moderate",
    pairs: [
      { name: "AUDNZD" },
      { name: "CADCHF", recommendedReduceLotSize: true },
      { name: "EURCAD" },
      { name: "EURGBP", recommendedReduceLotSize: true },
      { name: "EURUSD" },
      { name: "GBPJPY", recommendedReduceLotSize: true },
      { name: "GBPNZD", recommendedReduceLotSize: true },
      { name: "GBPUSD" },
      { name: "NZDCAD" },
      { name: "NZDUSD" },
      { name: "USDCAD", recommendedReduceLotSize: true },
      { name: "USDCHF", recommendedReduceLotSize: true },
      { name: "USDJPY", recommendedReduceLotSize: true }
    ]
  }
];