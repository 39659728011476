import { PropsWithChildren } from 'react';
import { AuthProvider } from './AuthenticationContext';
import { AccountDataProvider } from './AccountDataContext';
import { TooltipProvider } from '@/components/ui/tooltip';
import { UserAccountsProvider } from './UserAccountsContext';
import { FundsProvider } from './FundsContext';

export const RootProvider = ({ children }:PropsWithChildren): JSX.Element => {
  return (
    <AuthProvider>
      <UserAccountsProvider>
        <AccountDataProvider>
          
          <TooltipProvider>
            <FundsProvider>
              {children}   
            </FundsProvider>
          </TooltipProvider>
          
        </AccountDataProvider>
      </UserAccountsProvider>
    </AuthProvider>
  );
};
