import { AccountStatusResponse } from "@/types/FirebaseFunctionsResponses";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

class AccountStatusApi {
  getAccountStatus = async (accountId: string): Promise<AccountStatusResponse | undefined> => {
    try{
      const getAccountStatus = httpsCallable(functions, 'getAccountStatus');
      const result = await getAccountStatus({"accountId": accountId});
      const response: AccountStatusResponse = result.data as AccountStatusResponse;
      
      console.log("firebaseFunctionsApi.getAccountStatus response:", response);

      return response; 
    }
    catch(e){
      console.error("UserAccountsApi.getAccounts error:", e);
      return undefined;
    }
  }
}

export const AccountStatusApiInstance = new AccountStatusApi();