import Lottie from 'react-lottie-player'

import lottieJson from './currencies1.json'

export const AppLoader = () => {
  return (
    <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: '20%', height: '20%' }}
    />
  )
}