import { UsdMetrics } from "./MainMetrics";

interface FundsSummaryProps {
  balance: number;
  equity: number;
  profit: number;
  unrealizedProfit: number;
}

export const FundsSummary = ({ balance, equity, profit, unrealizedProfit }: FundsSummaryProps): JSX.Element => {
  const unrealizedProfitPercentage = balance > 0 ? (unrealizedProfit / balance) * 100 : 0;
  const profitPercentage = equity > 0 ? (profit / equity) * 100 : 0;
  const equityPercentage = balance > 0 ? (equity / balance ) * 100 : 0;

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
      <UsdMetrics title="Current Balance" 
        value={balance} 
        />
      <UsdMetrics title="Equity" 
        value={Math.abs(equity)} 
        progress={equityPercentage}
        progressTitle={equityPercentage.toFixed(2) + '% from balance'} 
        />
      <UsdMetrics title="Profit" 
        value={profit} 
        progress={profitPercentage}
        progressTitle={profitPercentage.toFixed(2) + '% from equity'} 
        />
      <UsdMetrics title="Unrealized Profit" 
        value={unrealizedProfit} 
        progress={unrealizedProfitPercentage}
        progressTitle={unrealizedProfitPercentage.toFixed(2) + '% from balance'} 
      />
    </div>
  );
};